import '../App.less';
import { useMsal } from "@azure/msal-react";
import RunRegister from './RunRegister';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { Spin } from 'antd';
import loader  from './quantum-loaderve1.gif'
import { FC, useEffect, useState } from 'react';
import Emitter from './util/eventEmitter';
import useWindowDimensions from './util/windows-dimension-hooks';
import { loginRequest } from '../authConfig';

const client = new ApolloClient({
    uri: process.env.REACT_APP_APOLLO_CLIENT,
    cache: new InMemoryCache({
        addTypename: false
    }),

});

const Run:FC =() =>{

    
    const [accessToken , setAccessToken] =  useState("")
    const { instance } = useMsal();

    // on mount call 'getFeatures'
    /* se crea instancia de msal para obtener el token */
    const account = instance.getAllAccounts()[0];   

    instance.acquireTokenSilent({
        account: account,
        scopes: loginRequest.scopes
        }).then((authority)=>{
            /* setea el token que se utilizara en las llamadas al api managment */
            setAccessToken(authority.idToken)
    })

   /* Eventos que se ejecutan desde cualquier microfront
     estos validan si se debe mostrar el GIF de carga o no
  */
     const [showSpin, setShowSpin] = useState(false)

      Emitter.on("SHOW_SPIN", () => { setShowSpin(true)  })
      Emitter.on("HIDE_SPIN", () => { setShowSpin(false)  })
      
      useEffect(() => {
        setShowSpin(showSpin)
      },[showSpin])

    return (
        <>
            {/* se ejecuta el registro de la aplicacion , una vez autenticado */}
            <ApolloProvider client={client}>
            <Spin
            spinning={showSpin}
            indicator={<><img alt="" width={85.4} height={62.31} src={loader}></img></>}
            size={"small"}
            style={{marginTop: useWindowDimensions().width > 896 ? "25%" : "70%"}}
            >
                {
                    accessToken ? <>
                     <RunRegister user={account} accessToken={accessToken} client={client}></RunRegister>

                    </> : <></>
                }
            </Spin>
            </ApolloProvider>

        </>
    )
}
export default Run;
/* Arreglo que contandro los submenus posibles dentro de una url
si no se encuentra dentro de un submenu , simplemente es una subruta de un componente */
const subMenus = [
    'users/cargar',
    'users/editar',
    'stock/detalle',
]


export function esSubMenu(submenu:string){
    return subMenus.find(x => x === submenu)
}

export function capitalize(word:string) {
    const lower = word.toLowerCase();
    return word.charAt(0).toUpperCase() + lower.slice(1);
}


export const validarVacio = (variable: any) => {
    let salida = "";
    if (variable !== undefined) {
      salida = variable;
    }
    return salida;
}

export const validaAppName = (nombre:string ) => {
    return nombre.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/ /g ,  "-")
  }
import { useMsal } from "@azure/msal-react";
import { Button, Result } from "antd"
import { FC} from "react";
import { msalConfig } from "../../authConfig";
import { obtenerIcono } from "../util/AppIcons";
import { urls } from "../util/constantes";
import useWindowDimensions from "../util/windows-dimension-hooks";


const SinRol:FC<{mostrarSinRol:boolean}> = (props) => {
 
  window.onpopstate = (e:any) => {
    const pathName = e.currentTarget.location.pathname;
    if (pathName === "/") {
      localStorage.setItem("currentPage", "1")
      e.currentTarget.location.pathname = msalConfig.auth.postLogoutRedirectUri
    }
  }

  const { instance } = useMsal();

    const logout = () =>{
        instance.logoutRedirect({ postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri })
      }

    const displaySinRol = props.mostrarSinRol ? "block" : "none" 
    return (
        <>
        <Result style={{paddingTop:"10%" , display:displaySinRol}}
                icon={obtenerIcono("alert-no-rol")}
                status="warning"
                title={<h1>¡UPS!</h1>}
                subTitle={<h3 style={{width:"30%" , marginLeft:"35%"}}>Aparentemente no tienes una cuenta o un rol asignado. Comuníquese con su jefatura para solventar este inconveniente.</h3>}
                extra={<>
                      <Button className="sin-rol-button" type="primary" key="console" onClick={() => logout()}>
                        <span className="button-text">Volver al login</span> 
                      </Button><br/>
                        <img
                        alt=""
                        className="logo-derco"
                        width={useWindowDimensions().width > 656 ? 550 : 250}
                        src={urls.logoAutopiaXL}
                        />
                 </>
            }
        />,
        </>
    )
}

export default SinRol
import { Menu } from "antd";
import { FC, Fragment,  useState } from "react";
import { obtenerIcono } from "../util/AppIcons";



export const MenuLateral: FC<{ handleClick: any, menu: any, selectedMenu: any }> = (props) => {


    const [_menu] = useState(props.menu.rol.menuTree)



    const obtenerMenuItem = (data: any) => {

        return (
            <Menu.Item onClick={() => props.handleClick(data.url, data.nombre.toLowerCase())}
                key={data.url}
                id={data.url}
                icon={obtenerIcono(data.icono?.toLowerCase())}>
                <label className="text-menu-lateral">{data.nombre} </label>
            </Menu.Item>
        )
    }


    const obtenerMenuSubmenu = (data:any) =>  { 
        return <Menu.SubMenu
        icon={obtenerIcono(data.icono?.toLowerCase())}
        key={data.nombre}
        title={
            <>
               <label className="text-menu-lateral">{data.nombre} </label>
            </>
        }
    >
        {

            data.submenus.map((subMenu:any , _index:number) => {
               return obtenerMenuItem(subMenu)
            })
        }
    </Menu.SubMenu>
    }


    return (

            <Menu theme="light" selectedKeys={props.selectedMenu} mode="inline" key={"MenuLateralIzq"}>
                {
                    _menu.map((data: any, _index: number) => {

                        let contieneSubMenu = data.submenus.length >= 1

                        return (
                            <Fragment key={data.url} >

                                {
                                    !contieneSubMenu ?
                                        obtenerMenuItem(data)
                                        :
                                        obtenerMenuSubmenu(data)

                                }
                            </Fragment>
                        )
                    })
                }

            </Menu>

    )

}
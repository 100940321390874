import { LogLevel } from "@azure/msal-browser";

const validarVacio = (variable) => {
    let salida = "";
    if(variable !== undefined){
        salida = variable;
    }
    return salida ;
}


const   REACT_APP_POLICY_SIGIN =  validarVacio(process.env.REACT_APP_POLICY_SIGIN);
const   REACT_APP_POLICY_RESET_PASS =  validarVacio(process.env.REACT_APP_POLICY_RESET_PASS);
const   REACT_APP_AUTHORITY =  validarVacio(process.env.REACT_APP_AUTHORITY);
const   REACT_APP_AUTH_DOMAIN =  validarVacio(process.env.REACT_APP_AUTH_DOMAIN);
const   REACT_APP_CLIENT_ID =  validarVacio(process.env.REACT_APP_CLIENT_ID);
const   REACT_APP_REDIRECT_URI =  validarVacio(process.env.REACT_APP_REDIRECT_URI);
const   REACT_APP_LOGOUT_URI =  validarVacio(process.env.REACT_APP_LOGOUT_URI);

/**
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies ={
    names: {
        signUpSignIn:   REACT_APP_POLICY_SIGIN,
        resetpassword:  REACT_APP_POLICY_RESET_PASS
    },
    authorities: {
        signUpSignIn: {
            authority: REACT_APP_AUTHORITY,
        }
    },
    authorityDomain: REACT_APP_AUTH_DOMAIN
}

/**
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md 
 */
export const msalConfig = {
    auth: {
        clientId: REACT_APP_CLIENT_ID, 
        authority: b2cPolicies.authorities.signUpSignIn.authority, 
        knownAuthorities: [b2cPolicies.authorityDomain], 
        redirectUri: REACT_APP_REDIRECT_URI,
        postLogoutRedirectUri: REACT_APP_LOGOUT_URI,
        navigateToLoginRequestUrl: false
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false,
        secureCookies: true
    },
    system: {	
        loggerOptions: {	
            loggerCallback: (level, message, containsPii) => {	
                let _message =  `MSAL : ${message}`
                if (containsPii) {		
                    return;		
                }		
                switch (level) {		
                    case LogLevel.Error:		
                        console.error(_message);		
                        return;			
                    case LogLevel.Warning:		
                        console.warn(_message);		
                        return;
                    default:
                        return;
                }	
            }	
        }	
    }
};








/**
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
    scopes: ["openid", "https://devquantum.onmicrosoft.com/api/user-read"],

};
import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import App from './App';
import {  BrowserRouter as Router, } from "react-router-dom";


export const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <React.StrictMode> 
    
    <Router>
       <App  msalInstance={msalInstance}/>

    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

 // se ejecuta el registro completo de la applicacion

import { Layout, Col, Row } from 'antd';
import logoFooter from "./logoFooter.png"
import SideMenu from './SideMenu/SideMenu.component';
import HeaderMenu from './HeaderMenu/HeaderMenu.component';
import { navigateToUrl } from 'single-spa';
import { FC, useState } from 'react';
import { IAccountInfo, UserMenu } from '../model/userMenu';
import useWindowDimensions from './util/windows-dimension-hooks';
import { validaAppName } from './util/transversales';

const { Content, Footer } = Layout;

const AppShell: FC<{ user: IAccountInfo, menu: UserMenu }> = (props) => {
  const styleContainerMF: React.CSSProperties = { width: "92%", paddingLeft: "5%" }
  const styleContainerMFMobile: React.CSSProperties = { width: "100%", paddingLeft: "16px", paddingRight: "16px" }

  const styleContainer = useWindowDimensions().width > 896 ? styleContainerMF : styleContainerMFMobile

  useState(() => {
    navigateToUrl("/home")
  })

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <SideMenu
        userName={`${props.menu.usuarioByEmail.nombre}
              ${props.menu.usuarioByEmail.apellido}`}
        menu={props.menu.usuarioByEmail}
      />
      <Layout className="site-layout" >
        <Row>
          <Col span={24}>
            {/* Header contiene logo y dropdown de cerrar sesion */}
            <HeaderMenu user={props.user}>
            </HeaderMenu>
          </Col>
        </Row>
        <Content id='hola'>
          {
            props.menu.usuarioByEmail.rol.accesos.map((x: any) => {
              const appName = validaAppName(x.menu.nombre) 
              return (
                <>
                  <div style={styleContainer} key={x.menu.nombre} id={`single-spa-application:app-${appName}`} />
                </>
              )
            })
          }
        </Content>
        <Footer className='footer'>
          <div className="logo" >
            <img
              alt=''
              className="logo-derco-footer"
              src={logoFooter}
            />
          </div>
          
        </Footer>
      </Layout>
    </Layout>

  )
};

export default AppShell;

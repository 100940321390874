export function obtenerIcono(key: string) {
    switch (key) {
        case "camera":
            return (
                <svg style={{ marginLeft: "-10px" }} width="36" height="33" viewBox="0 0 36 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M29 23C29 23.5304 28.7893 24.0391 28.4142 24.4142C28.0391 24.7893 27.5304 25 27 25H9C8.46957 25 7.96086 24.7893 7.58579 24.4142C7.21071 24.0391 7 23.5304 7 23V12C7 11.4696 7.21071 10.9609 7.58579 10.5858C7.96086 10.2107 8.46957 10 9 10H13L15 7H21L23 10H27C27.5304 10 28.0391 10.2107 28.4142 10.5858C28.7893 10.9609 29 11.4696 29 12V23Z" stroke="#33518D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M18 21C20.2091 21 22 19.2091 22 17C22 14.7909 20.2091 13 18 13C15.7909 13 14 14.7909 14 17C14 19.2091 15.7909 21 18 21Z" stroke="#33518D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            )

        case "home":
            return (
                <svg style={{ marginLeft: "-10px" }} width="36" height="33" viewBox="0 0 36 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 13L18 6L27 13V24C27 24.5304 26.7893 25.0391 26.4142 25.4142C26.0391 25.7893 25.5304 26 25 26H11C10.4696 26 9.96086 25.7893 9.58579 25.4142C9.21071 25.0391 9 24.5304 9 24V13Z" stroke="#33518D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M15 26V16H21V26" stroke="#33518D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            )
        case "admin":
            return (
                <svg style={{ marginLeft: "-4px" }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17 21V19C17 17.9391 16.5786 16.9217 15.8284 16.1716C15.0783 15.4214 14.0609 15 13 15H5C3.93913 15 2.92172 15.4214 2.17157 16.1716C1.42143 16.9217 1 17.9391 1 19V21" stroke="#33518D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M9 11C11.2091 11 13 9.20914 13 7C13 4.79086 11.2091 3 9 3C6.79086 3 5 4.79086 5 7C5 9.20914 6.79086 11 9 11Z" stroke="#33518D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M23 20.9999V18.9999C22.9993 18.1136 22.7044 17.2527 22.1614 16.5522C21.6184 15.8517 20.8581 15.3515 20 15.1299" stroke="#33518D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M16 3.12988C16.8604 3.35018 17.623 3.85058 18.1676 4.55219C18.7122 5.2538 19.0078 6.11671 19.0078 7.00488C19.0078 7.89305 18.7122 8.75596 18.1676 9.45757C17.623 10.1592 16.8604 10.6596 16 10.8799" stroke="#33518D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>

            )
        case "db":
            return (
                <svg style={{ marginLeft: "-4px" }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 8C16.9706 8 21 6.65685 21 5C21 3.34315 16.9706 2 12 2C7.02944 2 3 3.34315 3 5C3 6.65685 7.02944 8 12 8Z" stroke="#33518D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M21 12C21 13.66 17 15 12 15C7 15 3 13.66 3 12" stroke="#33518D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M3 5V19C3 20.66 7 22 12 22C17 22 21 20.66 21 19V5" stroke="#33518D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>


            )
        case "leads":
            return leadsIcon()

        case "leadsfinanciamiento":
            return leadsIcon()
        case "alert-no-rol":
            return (
                <svg width="177" height="148" viewBox="0 0 177 148" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M77.1222 2.82951C80.5954 0.974514 84.5141 0 88.5 0C92.4859 0 96.4046 0.974514 99.8778 2.82951C103.351 4.68451 106.262 7.35737 108.329 10.5902L108.351 10.625L173.829 114.319L173.892 114.42C175.917 117.747 176.989 121.518 177 125.36C177.011 129.201 175.962 132.978 173.957 136.316C171.951 139.653 169.06 142.434 165.57 144.382C162.079 146.33 158.112 147.378 154.063 147.42L153.978 147.421L22.9369 147.42C18.8876 147.378 14.9205 146.33 11.4304 144.382C7.94022 142.434 5.04863 139.653 3.04329 136.316C1.03795 132.978 -0.0112475 129.201 9.09321e-05 125.36C0.0114298 121.518 1.08291 117.747 3.10792 114.42L3.17072 114.319L68.6489 10.6251L75.2805 14.3934L68.671 10.5902C70.7382 7.35737 73.6489 4.68451 77.1222 2.82951ZM81.8997 18.1822L16.4716 121.796C15.8132 122.895 15.465 124.137 15.4612 125.401C15.4574 126.681 15.8072 127.94 16.4756 129.053C17.1441 130.165 18.1079 131.092 19.2713 131.742C20.4245 132.385 21.7339 132.734 23.0713 132.754H153.929C155.266 132.734 156.575 132.385 157.729 131.742C158.892 131.092 159.856 130.165 160.524 129.053C161.193 127.94 161.543 126.681 161.539 125.401C161.535 124.137 161.187 122.895 160.529 121.797L95.1097 18.1968C95.1066 18.1919 95.1034 18.187 95.1003 18.1822C94.4118 17.1111 93.4451 16.2254 92.2926 15.6099C91.1349 14.9915 89.8286 14.6667 88.5 14.6667C87.1714 14.6667 85.8651 14.9915 84.7074 15.6099C83.5549 16.2254 82.5882 17.1111 81.8997 18.1822Z" fill="#FBC02D" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M88.4962 44.7534C92.7657 44.7534 96.2268 48.0367 96.2268 52.0868V81.4202C96.2268 85.4703 92.7657 88.7535 88.4962 88.7535C84.2267 88.7535 80.7656 85.4703 80.7656 81.4202V52.0868C80.7656 48.0367 84.2267 44.7534 88.4962 44.7534Z" fill="#FBC02D" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M80.7656 110.753C80.7656 106.703 84.2267 103.419 88.4962 103.419H88.5735C92.843 103.419 96.3041 106.703 96.3041 110.753C96.3041 114.803 92.843 118.086 88.5735 118.086H88.4962C84.2267 118.086 80.7656 114.803 80.7656 110.753Z" fill="#FBC02D" />
                </svg>
            )
        case "menu-hamburguesa":
            return (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3 12H21" stroke="#33518D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M3 6H21" stroke="#33518D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M3 18H21" stroke="#33518D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            )
        case "cerrar-modal":
            return (

                <svg style={{ position: "fixed", paddingTop: "10px", marginLeft: "10px", marginTop: "15px" }} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="13" cy="13" r="13" fill="#33518D" />
                    <path d="M19 7L7 19" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M7 7L19 19" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            )
        case "logo-autopia":
            return (
                <svg width="208" height="56" viewBox="0 0 208 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_41_2544)">
                        <path d="M85.8542 16.1497C85.8542 13.8365 85.1132 12.094 83.629 10.9245C82.2575 9.86481 80.3812 9.33496 78.0022 9.33496C75.1184 9.33496 72.3602 9.93373 69.7298 11.1334L71.4524 14.9393C73.411 14.1036 75.3567 13.6857 77.2894 13.6857C79.6402 13.6857 80.8167 14.3276 80.8167 15.6091C80.8167 16.5288 79.6965 17.2396 77.4584 17.7414C74.1542 18.4673 72.0547 19.051 71.1598 19.4968C68.9195 20.6125 67.8015 22.3808 67.8015 24.806C67.8015 27.0073 68.8653 28.6808 70.993 29.8223C72.6722 30.714 74.7435 31.1599 77.207 31.1599C79.586 31.1599 82.5674 30.6171 86.151 29.5294C85.956 28.3039 85.8564 27.4251 85.8564 26.8953V16.1497H85.8542ZM77.2482 26.9793C74.646 26.9793 73.3439 26.1307 73.3439 24.4291C73.3439 23.5654 73.8617 22.8956 74.8974 22.4217C75.3459 22.2279 76.4227 21.935 78.13 21.543C79.5015 21.2371 80.3964 20.8731 80.8167 20.4553V25.5987C80.8167 25.7667 80.8449 26.0316 80.9012 26.3934C79.6684 26.7854 78.4507 26.9793 77.2482 26.9793Z" fill="#001F60" />
                        <path d="M109.579 9.7959H104.539V24.9741C104.539 25.1421 104.568 25.4328 104.624 25.8528C102.973 26.4667 101.558 26.7725 100.384 26.7725C97.6126 26.7725 96.2259 25.491 96.2259 22.9257V9.7959H91.1863V23.7184C91.1863 28.6808 94.2369 31.1599 100.34 31.1599C102.776 31.1599 105.952 30.4642 109.872 29.0685C109.674 27.731 109.577 26.7553 109.577 26.1414V9.7959H109.579Z" fill="#001F60" />
                        <path d="M123.056 27.063C121.767 27.063 121.125 26.4642 121.125 25.2646V14.1443H127.003V9.79564H121.125V4.06641H116.086V9.87748L113.694 10.0024V13.9741L116.086 14.099V25.6393C116.086 29.5141 118.241 31.4504 122.553 31.4504C124.009 31.4504 125.883 31.0606 128.18 30.2787L126.793 26.223C125.17 26.7852 123.925 27.063 123.056 27.063Z" fill="#001F60" />
                        <path d="M140.775 9.33496C137.611 9.33496 135.063 10.3602 133.133 12.4085C131.202 14.4568 130.236 17.0694 130.236 20.2485C130.236 23.4254 131.187 26.0187 133.092 28.026C135.05 30.1174 137.611 31.162 140.775 31.162C143.938 31.162 146.486 30.1368 148.417 28.0885C150.347 26.0402 151.313 23.4276 151.313 20.2485C151.313 17.0716 150.347 14.4568 148.417 12.4085C146.486 10.3602 143.938 9.33496 140.775 9.33496ZM144.638 24.763C143.769 26.1565 142.482 26.8543 140.775 26.8543C139.067 26.8543 137.778 26.1586 136.912 24.763C136.184 23.6214 135.82 22.1288 135.82 20.2894C135.82 18.45 136.184 16.9445 136.912 15.775C137.778 14.3814 139.067 13.6836 140.775 13.6836C142.482 13.6836 143.769 14.3943 144.638 15.8159C145.366 16.9876 145.73 18.478 145.73 20.2894C145.73 22.1288 145.366 23.6214 144.638 24.763Z" fill="#001F60" />
                        <path d="M163.659 9.33496C160.944 9.33496 158.201 9.83681 155.43 10.8405C155.597 12.0402 155.682 12.9168 155.682 13.4747V40.107H160.721V30.4491C161.98 30.923 163.295 31.1599 164.669 31.1599C167.553 31.1599 169.995 30.1282 171.997 28.067C173.999 26.0036 175 23.3845 175 20.2076C175 16.9747 173.977 14.3534 171.934 12.3482C169.888 10.3387 167.13 9.33496 163.659 9.33496ZM163.534 26.8113C162.526 26.8113 161.588 26.6174 160.719 26.2254V15.1051C160.719 14.9113 160.678 14.6313 160.593 14.2694C161.685 13.8796 162.749 13.6836 163.785 13.6836C165.659 13.6836 167.087 14.3254 168.068 15.607C168.935 16.7507 169.371 18.282 169.371 20.2054C169.371 24.61 167.425 26.8113 163.534 26.8113Z" fill="#001F60" />
                        <path d="M184.864 9.7959H179.825V30.7011H184.864V9.7959Z" fill="#001F60" />
                        <path d="M207.705 16.1497C207.705 13.8365 206.962 12.094 205.48 10.9245C204.109 9.86481 202.232 9.33496 199.853 9.33496C196.969 9.33496 194.211 9.93373 191.581 11.1334L193.303 14.9393C195.262 14.1036 197.208 13.6857 199.14 13.6857C201.491 13.6857 202.668 14.3276 202.668 15.6091C202.668 16.5288 201.548 17.2396 199.309 17.7414C196.005 18.4673 193.908 19.051 193.011 19.4968C190.771 20.6125 189.653 22.3808 189.653 24.806C189.653 27.0073 190.716 28.6808 192.844 29.8223C194.523 30.714 196.595 31.1599 199.058 31.1599C201.437 31.1599 204.418 30.6171 208.002 29.5294C207.805 28.3039 207.707 27.4251 207.707 26.8953V16.1497H207.705ZM199.097 26.9793C196.493 26.9793 195.193 26.1307 195.193 24.4291C195.193 23.5654 195.711 22.8956 196.746 22.4217C197.193 22.2279 198.272 21.935 199.979 21.543C201.35 21.2371 202.247 20.8731 202.666 20.4553V25.5987C202.666 25.7667 202.694 26.0316 202.75 26.3934C201.519 26.7854 200.302 26.9793 199.097 26.9793Z" fill="#001F60" />
                        <path d="M182.344 0C180.661 0 179.298 1.35908 179.298 3.03262C179.298 4.70831 180.661 6.06523 182.344 6.06523C184.028 6.06523 185.391 4.70615 185.391 3.03262C185.391 1.35908 184.028 0 182.344 0Z" fill="#001F60" />
                        <path d="M6.06883 40.1068C2.717 40.1068 0 37.4015 0 34.0652V16.8409C0 7.55354 7.58767 0 16.913 0C26.2383 0 33.826 7.55354 33.826 16.8409V20.0932C33.826 23.4295 31.109 26.1369 27.7572 26.1369C24.4053 26.1369 21.6883 23.4317 21.6883 20.0932V16.8409C21.6883 14.2197 19.5455 12.0852 16.913 12.0852C14.2805 12.0852 12.1377 14.2175 12.1377 16.8409V34.0652C12.1377 37.4015 9.42067 40.1068 6.06883 40.1068Z" fill="#E1251B" />
                        <path d="M49.4391 0C52.7909 0 55.5079 2.70523 55.5079 6.04369V23.268C55.5079 32.5532 47.9202 40.1089 38.5949 40.1089C29.2696 40.1089 21.6819 32.5554 21.6819 23.268V20.0932C21.6819 16.7548 24.3989 14.0495 27.7507 14.0495C31.1026 14.0495 33.8196 16.7548 33.8196 20.0932V23.268C33.8196 25.8892 35.9624 28.0215 38.5949 28.0215C41.2274 28.0215 43.3702 25.8892 43.3702 23.268V6.04369C43.3724 2.70523 46.0894 0 49.4391 0Z" fill="#10CFC9" />
                        <path d="M33.8239 20.0911C33.8239 16.7526 31.1069 14.0474 27.7529 14.0474C24.4011 14.0474 21.6819 16.7547 21.6819 20.0911C21.6819 23.4274 24.3989 26.1347 27.7529 26.1347C31.1069 26.1369 33.8239 23.4295 33.8239 20.0911Z" fill="#001F60" />
                        <path d="M163.525 47.2147C163.05 46.7667 162.478 46.4114 161.824 46.1594C161.17 45.9074 160.44 45.7803 159.657 45.7803H155.708V55.8301H159.657C160.44 55.8301 161.17 55.7009 161.824 55.4424C162.478 55.1861 163.05 54.8286 163.525 54.3806C163.999 53.9326 164.37 53.3963 164.63 52.7867C164.888 52.1772 165.02 51.5095 165.02 50.8052V50.7772C165.02 50.0729 164.89 49.4074 164.63 48.8021C164.37 48.1969 163.997 47.6627 163.525 47.2147ZM159.657 53.7344H158.026V47.876H159.657C160.093 47.876 160.498 47.9492 160.86 48.0914C161.22 48.2335 161.532 48.436 161.785 48.6944C162.039 48.9507 162.24 49.2652 162.383 49.6227C162.526 49.9824 162.6 50.3809 162.6 50.8052V50.8332C162.6 51.2683 162.526 51.6646 162.383 52.0157C162.24 52.3667 162.039 52.6747 161.783 52.9311C161.529 53.1874 161.217 53.3877 160.858 53.5255C160.498 53.6634 160.093 53.7344 159.657 53.7344Z" fill="#1D1D1B" />
                        <path d="M169.26 51.8024H173.959V49.7347H169.26V47.848H174.601V45.7803H166.97V55.8301H174.672V53.7624H169.26V51.8024Z" fill="#1D1D1B" />
                        <path d="M184.479 51.1474C184.879 50.6326 185.083 49.9584 185.083 49.1443V49.1163C185.083 48.1578 184.788 47.3803 184.21 46.8031C183.527 46.1224 182.535 45.7778 181.261 45.7778H176.642V55.8277H178.96V52.6507H180.587L182.724 55.8277H185.441L182.984 52.2501C183.59 52.0111 184.093 51.6406 184.479 51.1474ZM178.958 47.8757H181.075C181.599 47.8757 182.013 47.9898 182.303 48.216C182.589 48.4378 182.734 48.7803 182.734 49.2304V49.2584C182.734 49.6634 182.596 49.9908 182.321 50.232C182.043 50.4754 181.638 50.5981 181.116 50.5981H178.958V47.8757Z" fill="#1D1D1B" />
                        <path d="M194.445 52.7154C194.062 53.0686 193.672 53.3529 193.284 53.5576C192.9 53.76 192.424 53.8613 191.869 53.8613C191.453 53.8613 191.067 53.7794 190.721 53.62C190.374 53.4585 190.075 53.2366 189.83 52.9609C189.585 52.6853 189.39 52.3579 189.252 51.9874C189.113 51.6191 189.042 51.2206 189.042 50.8049V50.7769C189.042 50.3613 189.113 49.9649 189.252 49.6009C189.39 49.2369 189.588 48.9117 189.837 48.636C190.086 48.3603 190.387 48.1406 190.734 47.9856C191.08 47.8283 191.462 47.7508 191.869 47.7508C192.361 47.7508 192.807 47.8477 193.202 48.0416C193.598 48.2376 193.992 48.5089 194.376 48.8536L194.424 48.8966L195.899 47.2016L195.858 47.1606C195.63 46.9345 195.377 46.7212 195.11 46.5296C194.842 46.3379 194.545 46.1742 194.231 46.0406C193.914 45.9071 193.559 45.8016 193.171 45.724C192.786 45.6486 192.352 45.6099 191.884 45.6099C191.111 45.6099 190.394 45.7477 189.752 46.0191C189.111 46.2905 188.554 46.6674 188.095 47.1391C187.635 47.6108 187.271 48.1665 187.014 48.7889C186.756 49.4136 186.624 50.092 186.624 50.8049V50.8329C186.624 51.5459 186.754 52.2265 187.014 52.8554C187.271 53.4843 187.635 54.0379 188.095 54.4988C188.554 54.9619 189.107 55.3302 189.739 55.5973C190.368 55.8643 191.061 56 191.798 56C192.283 56 192.729 55.9591 193.121 55.8794C193.514 55.7976 193.88 55.677 194.211 55.5197C194.541 55.3625 194.85 55.1708 195.128 54.9533C195.405 54.7336 195.676 54.4837 195.932 54.2102L195.973 54.1671L194.493 52.6788L194.445 52.7154Z" fill="#1D1D1B" />
                        <path d="M207.584 48.7609C207.322 48.1363 206.947 47.5849 206.475 47.124C206.002 46.6631 205.432 46.2905 204.782 46.0191C204.132 45.7477 203.411 45.6099 202.637 45.6099C201.864 45.6099 201.138 45.7477 200.484 46.0191C199.829 46.2905 199.257 46.6674 198.783 47.1391C198.308 47.6108 197.934 48.1665 197.665 48.7889C197.396 49.4136 197.262 50.092 197.262 50.8049V50.8329C197.262 51.548 197.396 52.2265 197.658 52.8489C197.921 53.4736 198.295 54.0228 198.768 54.4859C199.24 54.9468 199.81 55.3194 200.46 55.5908C201.11 55.8622 201.831 56 202.605 56C203.378 56 204.104 55.8622 204.759 55.5908C205.413 55.3194 205.985 54.9425 206.459 54.4708C206.934 53.9991 207.309 53.4434 207.577 52.8209C207.846 52.1963 207.98 51.5179 207.98 50.8049V50.7769C207.98 50.0619 207.846 49.3834 207.584 48.7609ZM202.633 53.8613C202.198 53.8613 201.797 53.7794 201.441 53.6179C201.086 53.4563 200.77 53.2345 200.508 52.9588C200.243 52.6831 200.037 52.3557 199.894 51.9874C199.751 51.6191 199.678 51.2206 199.678 50.8049V50.7769C199.678 50.3613 199.751 49.9649 199.894 49.6009C200.037 49.2369 200.239 48.9117 200.495 48.636C200.748 48.3603 201.058 48.1406 201.413 47.9856C201.769 47.8283 202.169 47.7508 202.605 47.7508C203.03 47.7508 203.428 47.8326 203.79 47.9942C204.152 48.1557 204.468 48.3776 204.733 48.6533C204.997 48.9289 205.203 49.2563 205.346 49.6246C205.489 49.9929 205.562 50.3914 205.562 50.8071V50.8351C205.562 51.2508 205.489 51.6449 205.346 52.0111C205.203 52.3751 205.001 52.7003 204.746 52.9782C204.492 53.2539 204.18 53.4736 203.82 53.6286C203.459 53.7816 203.06 53.8613 202.633 53.8613Z" fill="#1D1D1B" />
                    </g>
                    <defs>
                        <clipPath id="clip0_41_2544">
                            <rect width="208" height="56" fill="white" />
                        </clipPath>
                    </defs>
                </svg>

            )
        case "open-side-bar":
            return (
                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="14" cy="14" r="14" fill="#33518D" />
                    <path d="M11 8L17 14L11 20" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>

            )
        case "close-side-bar":
            return (
                <svg style={{ marginLeft: "200px" }} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="14" cy="14" r="14" fill="#33518D" />
                    <path d="M17 20L11 14L17 8" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            )
        default:
            break;
    }
}


const leadsIcon = () => (
    <svg style={{ marginLeft: "-4px" }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16 4H18C18.5304 4 19.0391 4.21071 19.4142 4.58579C19.7893 4.96086 20 5.46957 20 6V20C20 20.5304 19.7893 21.0391 19.4142 21.4142C19.0391 21.7893 18.5304 22 18 22H6C5.46957 22 4.96086 21.7893 4.58579 21.4142C4.21071 21.0391 4 20.5304 4 20V6C4 5.46957 4.21071 4.96086 4.58579 4.58579C4.96086 4.21071 5.46957 4 6 4H8" stroke="#33518D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15 2H9C8.44772 2 8 2.44772 8 3V5C8 5.55228 8.44772 6 9 6H15C15.5523 6 16 5.55228 16 5V3C16 2.44772 15.5523 2 15 2Z" stroke="#33518D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>


)
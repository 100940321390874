import { Layout,Modal } from 'antd';
import logoPequeño from "../logoPequeño.svg"
import { FC,  useEffect, useState } from 'react';
import { navigateToUrl } from 'single-spa';
import useWindowDimensions from '../util/windows-dimension-hooks';
import Emitter from "../util/eventEmitter";
import { obtenerIcono } from '../util/AppIcons';
import { conformarFecha } from '../util/FechaHeader';
import { capitalize, esSubMenu } from '../util/transversales';
import { createBrowserHistory } from 'history';
import {  IUsuarioByEmail } from '../../model/userMenu';
import { urls } from '../util/constantes';
import { Saludo } from './SaludoComponent';
import { MenuLateral } from './MenuComponent';


const { Sider } = Layout;
const SideMenu:FC<{userName:string , menu:IUsuarioByEmail}> = (props) => {
    const fechaActual = conformarFecha()
    const [userName , setUserName] = useState(localStorage.getItem("userName"));
    const logoGrande = urls.logoAutopiaXL;
    const logoPeque = logoPequeño;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedMenu , setSelectedMenu] = useState(["/home"])

    /* Escucha cualquier cambio en la url para luego setear el item del menu */
    const history = createBrowserHistory()
    useEffect(() => {
        return history.listen((e) => {
            const selectItemUrl = e.location.pathname
            if(selectItemUrl.split("/").length > 2) {
                if(esSubMenu(selectItemUrl)) {
                    setSelectedMenu([capitalize(selectItemUrl)])
                }
            }else{
                setSelectedMenu([capitalize(selectItemUrl)])
            }
        })
    },[history])


    useEffect(() => {
        setSelectedMenu(selectedMenu)
    },[selectedMenu])

    useEffect(() => {
        setUserName(userName)
    },[userName])

    const handleClick = (url: string, selectedItem: string): void => {
        setIsModalVisible(false)
        Emitter.emit('CHANGE_URL', selectedItem);
        Emitter.emit('VALIDAR_CAMBIOS_MF', url);
    }

    /* Evento que se agrega dentro de cada mf ,
       en caso de que sea necesario realizar una validacion
       antes de realizar la navegacion correspondiente */
    Emitter.on('VALIDAR_CAMBIOS_MF',(url:string) => {
       const response = Emitter.emit('BUSCAR_VALIDACIONES',url)
        if(!response){
            navigateToUrl(url)
        }
    })



    const [collapsed, setState] = useState(false);
    const [logoStyle, setLogoStyle] = useState({
        width: 256,
        imagen: logoGrande,
        marginLeft:""
    });

    const onCollapse = (collapsed: boolean) => {
        if (collapsed) {
            setLogoStyle({ width: 100, imagen: logoPeque , marginLeft:"40px" })
        }
        if (!collapsed) {
            setLogoStyle({ width: 256, imagen: logoGrande  , marginLeft:""})
        }
        setState(collapsed);
    };


    const setDisplaysideMenu = (showMenu: boolean) => {
        if (!showMenu) {

            setIsModalVisible(false);

        }
    }

    Emitter.on('SHOW_MENU_MOBILE', (showMenu: boolean) => {
        setDisplaysideMenu(showMenu)
        showModal()
    })


    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };






    const selectSideIcon = () => {
        if(collapsed){
            return obtenerIcono('open-side-bar');

        }else{
        return obtenerIcono('close-side-bar');

        }
    }


    return (

        (useWindowDimensions().width > 896) ?
            <>

                <Sider  trigger={selectSideIcon()} className="side-menu-derco" width={ 256} collapsedWidth={100}    theme="light" collapsible collapsed={collapsed} onCollapse={onCollapse}>
                    <div className="logo" >
                        <img
                            alt=''
                            onClick={() => handleClick('/home', '')}
                            className="logo-derco"
                            width={logoStyle.width}
                            src={logoStyle.imagen}
                        />
                    </div>
                    {
                        !collapsed && <Saludo saludo='Bienvenido , ' userName={props.userName}>

                        </Saludo>
                    }
                        {
                            <MenuLateral handleClick={handleClick} menu={props.menu} selectedMenu={selectedMenu}></MenuLateral>
                        }
                </Sider>
            </>

            :
            <>
                <Modal className='side-menu' style={{ marginLeft: "0px", marginTop: "0px", top: "0px", height: "100%" }} footer={null} width={235} closable={false} confirmLoading={false} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                    <Sider theme="light" style={{ position: "absolute", zIndex: 1, height: "90%", borderRadius: "25px" }} collapsed={false} onCollapse={onCollapse}>
                        <div className="logo" >

                            <img
                                alt=''
                                onClick={() => handleClick('/home', '')}
                                className="logo-derco"
                                width={200}
                                src={logoGrande}
                            />


                        <span onClick={() => setDisplaysideMenu(false)}>
                            {
                                obtenerIcono('cerrar-modal')
                            }
                        </span>
                            <h5 style={{ 
                                marginLeft:"20px" ,
                                marginTop: "20px", 
                                color: "rgba(51, 81, 141, 1)",
                                fontWeight: "700 bold",

                                //styleName: Cuerpo 2 Bold;
                                fontSize: "14px"

                                
                                }}>{fechaActual}</h5>
                            
                        </div>
                        {
                            !collapsed && <Saludo saludo='Bienvenido , ' userName={props.userName}></Saludo>
                        }

                        {
                             <MenuLateral handleClick={handleClick} menu={props.menu} selectedMenu={selectedMenu}></MenuLateral>

                        }
                    </Sider>
                </Modal>





            </>
    )

}

export default SideMenu;


/* Textos generales del app shell */
export const text  ={
    cerrarSesion:'Cerrar Sesion',
}

/* urls generales del app shell */
export const urls = {
    pictures:'/pictures',
    logoAutopiaXL:"https://autopiadevelopmentglosa.blob.core.windows.net/theme-loginb2c/src/images/logo.svg"
}


export const events = {
    mostrarMobile : 'SHOW_MENU_MOBILE',
    mostrarLoader : 'SHOW_SPIN',
}
import { ApolloClient, NormalizedCacheObject, useQuery } from '@apollo/client';
import { FC, useEffect, useState } from 'react';
import { LifeCycles, registerApplication, start } from 'single-spa';
import AppShell from './AppShell';
import SinRol from './SinRol/SinRol';
import Emitter from './util/eventEmitter';
import { IAccountInfo, UserMenu } from './../model/userMenu'
import { OBTENER_USUARIO } from '../queries/usuarios';
import { validaAppName } from './util/transversales';

const RunRegister: FC<{ user: IAccountInfo, accessToken: string, client: ApolloClient<NormalizedCacheObject> }> = (props) => {

  const [isRegister, setIsRegister] = useState(false)
  const [isUser, setIsUser] = useState(false)
  const [mostrarSinRol, setMostrarSinRol] = useState(false)
  const generarLogConsola = () => {
    console.error("ERROR AL OBTENER ROLES " , error)
    console.error("ERROR AL OBTENER ROLES EMAIL " , props.user.username)
    console.error("ERROR AL OBTENER ROLES TOKEN : " , `Bearer ${props.accessToken}`)
  }

  /* apollo  obtiene roles */
  const { loading, error ,  data } = useQuery(OBTENER_USUARIO, {
    variables: { email: props.user.username },
    context: { headers: { authorization: `Bearer ${props.accessToken}` } }
  });

  if(error){  
    generarLogConsola()
  }



  /* 
      realizara el registro solo si existen roles o un usuario valido , en caso contrario
      no se registra ningun MF 
   */
  useEffect(() => {
    if (loading === false && isRegister === false) {
      const _userMenu: UserMenu = { ...data } 
      if (_userMenu.usuarioByEmail?.rol != null) {
        Emitter.emit("HIDE_SPIN")
        setIsRegister(true)
        setIsUser(true)
        const accesos = _userMenu.usuarioByEmail.rol.accesos

        for (const acceso of accesos) {
          const menu = acceso.menu
          const appName = validaAppName(menu.nombre)
          const pathName = menu.url
          const permisos = acceso.operaciones
          /* Registra un microfront , se debe indicar : 
            @activeWhen : indica cual sera la ruta que activara el MF
            @customProps : propiedades que se pueden heredar al MF  (token de authenticacion , valores de API , etc)
          */

          registerApplication({
            name: `app-${appName}`,
            app: (): Promise<LifeCycles> => (window as any).System.import(`@single-spa/app-${appName}`),
            activeWhen: `${pathName}`,

            customProps: {
              authToken: props.accessToken,
              apiAuth: process.env.REACT_APP_API_AUTH,
              apiFileUpload: process.env.REACT_APP_FILE_UPLOAD,
              apiStock: process.env.REACT_APP_URL_STOCK,
              ipaApi: process.env.REACT_APP_IPA_DERCO_SERVICES,
              serviceUri: process.env.REACT_APP_APOLLO_CLIENT,
              urlStorageAccount: process.env.REACT_APP_URL_STORAGE_ACCOUNT,
              urlProcesandoPicture: process.env.REACT_APP_URL_PROCESANDO_IMAGEN,
              accesos: appName === 'home' ? accesos : null,
              permisos: permisos,
              emitter: Emitter,
              apolloClient: props.client,
              urlPDP: process.env.REACT_APP_URL_PDP,


              amicarUrlApi: process.env.REACT_APP_AMICAR_API,
              amicarDatoIdType: process.env.REACT_APP_AMICAR_IDTYPE,
              amicarDatoIdValue: process.env.REACT_APP_AMICAR_IDVALUE,
              amicarDatoEmail: process.env.REACT_APP_AMICAR_EMAIL,
              amicarDatoPhone: process.env.REACT_APP_AMICAR_PHONE,
              amicarDatoVhType: process.env.REACT_APP_AMICAR_VHTYPE,
              amicarDatoType: process.env.REACT_APP_AMICAR_TYPE,
              amicarDatoAcces: process.env.REACT_APP_AMICAR_ACCES,
              amicarDatoTrdCar: process.env.REACT_APP_AMICAR_TRDCAR,
              amicarDatoRateTp: process.env.REACT_APP_AMICAR_RATETP,
              amicarDatoInmOption: process.env.REACT_APP_AMICAR_INMOPTION,
              amicarDatoSlChannel: process.env.REACT_APP_AMICAR_SLCHANNEL,
              amicarDatoSlChannelTp: process.env.REACT_APP_AMICAR_SLCHANNELTYP,
              amicarDatoSlRoomId: process.env.REACT_APP_AMICAR_SLROOMID,
              
            },
          });

          start({
            urlRerouteOnly: true,
          });

        }

      } else {
        levantarSinRol()
      }
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, isRegister, data, props.accessToken, props.client ])

  const levantarSinRol = () => {
    generarLogConsola()
    Emitter.emit("SHOW_SPIN")
    setMostrarSinRol(true)
    Emitter.emit("HIDE_SPIN")
  }


  return (
    /* 
    Si isUser == false , no tiene rol , en caso contrario ,
    Inicia el appshell con la authenticacion y los microfronts registrados listos para funcionar */

    <>

      {
         !isUser
        
            ? 
        
            <SinRol mostrarSinRol={mostrarSinRol} ></SinRol> 
            
            :

          /* El appshell , se encuentra contenido dentro del spin loader , para asi mostrar este por sobre toda la app */

          <AppShell user={props.user} menu={data} />


      }


    </>
  );
}

export default RunRegister;

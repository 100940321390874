import { FC } from "react";
import { Divider } from 'antd';



export const Saludo:FC<{saludo:string , userName:string}> = (props) => {
    
    return (
        <div className="informacion-cliente" >
        <div>
            <label>{props.saludo}</label>
            
            <h3 style={{color:"rgba(51, 81, 141, 1)" , fontWeight:"700" , lineHeight:"24.55px"}}>{props.userName}</h3>
            <Divider style={{marginBottom:"35px"}} />
        
        </div>
    </div>
    )
}
import { Menu } from 'antd';
import  { FC, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import useWindowDimensions from '../util/windows-dimension-hooks';
import { events, text} from '../util/constantes';
import { msalConfig } from '../../authConfig';
import Emitter from "../util/eventEmitter";
import { conformarFecha } from '../util/FechaHeader';
import { IAccountInfo } from '../../model/userMenu';
import { validarVacio } from '../util/transversales';
import { HeaderMenuDesktop } from './HeaderDesktop.component';
import { HeaderMenuMobile } from './HeaderMobile.component';

const HeaderMenu:FC<{user:IAccountInfo}> = (props) => {

  const [showMenu, setShowMeny] = useState(false)
  const fechaActual = conformarFecha()
  const { instance } = useMsal();

  const emitShowMenu = () => {
    Emitter.emit(events.mostrarMobile, showMenu);
    setShowMeny(!showMenu)
  }

  const emitShowLoader = () => {
    Emitter.emit(events.mostrarLoader)
    Emitter.off(events.mostrarLoader)
  }


  const logout = () => {
    emitShowLoader()
    localStorage.clear();

    const idp_access_token = validarVacio(props.user.idTokenClaims.idp_access_token)
    if(idp_access_token){
      instance.logoutRedirect({ postLogoutRedirectUri:process.env.REACT_APP_LOGOUT_OUTLOOK })
    }else{
      instance.logoutRedirect({ postLogoutRedirectUri:msalConfig.auth.postLogoutRedirectUri })

    }

  }

  const menu = (
    /* Boton cerrar sesion*/
    <Menu>
      <Menu.Item onClick={() => logout()} className='button-primary-right'  >
        {text.cerrarSesion}
      </Menu.Item>
    </Menu>
  );


  return (

    (useWindowDimensions().width > 896) ?
      <>
        <HeaderMenuDesktop fechaActual={fechaActual} menu={menu} ></HeaderMenuDesktop>
      </>
      :
      <>
        <HeaderMenuMobile emitShowMenu={emitShowMenu} menu={menu} ></HeaderMenuMobile>

      </>
  )

}

export default HeaderMenu;

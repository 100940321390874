
export const nombreDelDiaSegunFecha = (fecha:any) => [
    'Domingo',
    'Lunes',
    'Martes',
    'Miércoles',
    'Jueves',
    'Viernes',
    'Sábado',
    'Domingo',
  ][new Date(fecha).getDay()];

  export const numeroDeldiaSegunFecha = (fecha:any) => {
      return new Date().getDate()
  }
  export const numeroDelAnio = (fecha:any) => {
    return new Date().getFullYear()
  }

  export const nombreDelMes = (fecha:any) => [
    'enero',
    'febrero',
    'marzo',
    'abril',
    'mayo',
    'junio',
    'julio',
    'agosto',
    'septiembre',
    'octubre',
    'noviembre',
    'diciembre',
    'enero',
  ][new Date(fecha).getMonth()];




  export const conformarFecha = () => {
      const fechaActual = new Date();
      const diaNombre = nombreDelDiaSegunFecha(fechaActual)
      const diaNumero = numeroDeldiaSegunFecha(fechaActual)
      const mes = nombreDelMes(fechaActual)
      const anio =numeroDelAnio(fechaActual)
      const stringDate = `${diaNombre} , ${diaNumero} de ${mes} de ${anio}`
      return stringDate;
  }
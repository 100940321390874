import { gql } from "@apollo/client";

export const OBTENER_USUARIO = gql`
query GetUserByEmail($email:String!) {
  usuarioByEmail(email: $email) {
    id
    email
    nombre
    apellido
    estado
    telefono
    pais
    activo
    fechas {
        fechaCreacion
        fechaActualizacion
        fechaValidacionEmail
        ultimaSesion
    },
    rol { 
        id       
        rol
        posicion
        tipo
        accesos {
            menu {
                nombre
                url
                icono
            }
            operaciones
        }
        menuTree {
            id
            nombre
            url
            icono
            operaciones
            posicion
            menuPadre
            submenus {
                id
                nombre
                url
                icono
                operaciones
                posicion
                menuPadre                    
            }
        }
    }
  }
}
`;

import { Header } from 'antd/lib/layout/layout';
import { Dropdown, Avatar } from 'antd';
import { FC } from 'react';
import {
  UserOutlined
} from '@ant-design/icons';
import { obtenerIcono } from '../util/AppIcons';
import { urls } from '../util/constantes';

export const  HeaderMenuMobile:FC<{emitShowMenu:any , menu:any}> = (props) => { 
    const logoGrande = urls.logoAutopiaXL;

    return (

      <Header className="site-layout-background" style={{padding:16 ,  zIndex: 1, background: '#F1F1F1' }}>
      <span onClick={props.emitShowMenu} >
        {
          obtenerIcono("menu-hamburguesa")
        }

      </span>
      <img
        alt=''
        style={{ marginLeft: "0px" }}
        className="logo-derco-menu"
        width={200}
        src={logoGrande}
      />
      <Dropdown overlay={props.menu}>
        <a style={{ paddingTop: "10px" }} href="/" className="anchor-primary-right" onClick={e => e.preventDefault()}  >
          <Avatar className='button-primary-right' style={{ backgroundColor: '#002570' }} icon={<UserOutlined />} />
        </a>
      </Dropdown>
    </Header>
    )
}
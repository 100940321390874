import { Header } from 'antd/lib/layout/layout';
import {Dropdown, Avatar, PageHeader } from 'antd';
import { FC } from 'react';
import {
  UserOutlined,
} from '@ant-design/icons';
import { Divider } from 'antd';

export const  HeaderMenuDesktop:FC<{fechaActual:string , menu:any}> = (props) => { 
  let styleHeader:React.CSSProperties = {
      width:"100%",
      background: '#F1F1F1'   
    }
    let styleDateH3: React.CSSProperties = { 
      marginTop: "50px",
      color: "#33518D",
      fontWeight: "700 bold",
      fontFamily: "Nunito",
      fontSize: "14px",
      lineHeight: "19px",
      letterSpacing: "0em",
  
    }
    let cerrarCesionAnchor:React.CSSProperties = { paddingTop: "35px"  }
    let cerrarCesionAvatar:React.CSSProperties = { backgroundColor: '#002570' }


  return (

    <Header className="site-layout-background" style={styleHeader}>
    <PageHeader
      title={<h3 style={styleDateH3}>{props.fechaActual}</h3>}
      extra={[
        <Dropdown overlay={props.menu}>
        <a style={cerrarCesionAnchor} href="/" className="anchor-primary-right" onClick={e => e.preventDefault()}  >
          <Avatar className='button-primary-right' style={cerrarCesionAvatar} icon={<><UserOutlined /></>} />

        </a>

      </Dropdown>
      ]}
    ></PageHeader>
        <Divider />
    </Header>
  )
}
import './App.less';
import { MsalProvider  , MsalAuthenticationTemplate } from "@azure/msal-react";
import Run from './components/Run';
import { InteractionType, IPublicClientApplication } from '@azure/msal-browser';
import { FC } from 'react';

const App:FC<{msalInstance:IPublicClientApplication}> = (props) => {
    
    return (
        
        /*
         Template de Msal encargado de validar la autenticacion y sesion activa .
        interactionType indica como se mostrara el LOGIN , puede ser redireccionando en la pagina
        o levantar un modal
         */
        <MsalProvider instance={props.msalInstance}>
            <MsalAuthenticationTemplate
            
                interactionType={InteractionType.Redirect} >
                
                {/* Si Msal esta OK , se inicia la app */}
                <Run />
                
              </MsalAuthenticationTemplate>
        </MsalProvider>  
    );
}

export default App